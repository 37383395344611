import React from "react";
import "./aboutValue.scss";
import cn from "classnames";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const AboutValue = ({ data }) => {
  console.log(data);
  return (
    <div className="about-value">
      <div className="container">
        <h2>{data.title}</h2>
      </div>

      {Object.entries(data.valueList).map(([key, item], index) => {
        const image = getImage(item.image.localFile);
        return (
          <div key={key} className="about-value-block">
            <div className="container">
              <div className="image-wrap">
                <GatsbyImage
                  className="about-value-item-image"
                  image={image}
                  alt=""
                />
              </div>
              <div className="text-wrap">
                <div className="title-wrap">
                  <span>{index + 1}</span>
                  <h3>{item.title}</h3>
                </div>
                <div
                  className="content-wrap"
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AboutValue;
