import React from "react";
import "./aboutHero.scss";
import cn from "classnames";
import { StaticImage } from "gatsby-plugin-image";

const AboutHero = ({ data }) => {
  return (
    <div className="about-hero">
      <div className="container-wide">
        <div className="about-hero-top">
          <div className="about-hero-top-left"></div>
          <div className="about-hero-top-main">
            <div className="text-wrap">
              <h1 className="visuallyhidden">About Us</h1>
              <h2 className="h1">{data.title}</h2>
              <p
                className="after-heading-text"
                dangerouslySetInnerHTML={{ __html: data.content }}
              />
            </div>
          </div>
        </div>
        <div className="about-hero-bottom">
          <div className="about-hero-bottom-left"></div>
          <div className="about-hero-bottom-main">
            <div className="image-wrap">
              <StaticImage
                src="../../assets/images/about/about-hero-photo.png"
                alt=""
                width={672}
                height={491}
              />
            </div>
            <div
              className="text-wrap"
              dangerouslySetInnerHTML={{ __html: data.paragraphs }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutHero;
